import { HTTP } from "../https";

export const state = {
    position: {
    },
    jobs:{

    },
    requirements: {
    },
    tag: {
    },
    skillCategory: {
    },
    skillCategoryList:[],
    skills: {
    },
    tagList:[],
    pagination:{
        currentPage:0,
        rows:0,
        perPage:0
    },
   
    isError:{
        content:false,
        message:''
    },
    success:false,
    isLoading:false

};

export const getters = {
    processingData: state => state.isLoading,
    error: state => state.isError.content,
    success: state => state.success,
    getPosition: state => state.position,
    getTagList: state => state.tagList,
    getSkillCategoryList:state=>state.skillCategoryList,
    getPagination: state => state.pagination,
    getRequirements:state =>state.requirements,
    getSkillCategory:state =>state.skillCategory,
    getSkills:state =>state.skills,
    getTags:state =>state.tag,
    getJobs:state =>state.jobs,

};

export const mutations = {

    setPosition(state, newValue) {
        state.position = newValue
    },
    setLoading(state,newValue){
        state.isLoading = newValue
    },
    setError(state,newValue){
        state.isError.content = newValue.data.data
        state.isError.message = newValue.data.message
    },
    setErrorFalse(state,newValue){
        state.isError.content = newValue
        state.isError.message =''
    },
    setSuccess(state,newValue){
        state.success = newValue
    },
    setPagination(state,newValue){
        state.pagination.currentPage = newValue.current_page
        state.pagination.rows = newValue.total
        state.pagination.perPage = newValue.per_page
    },
    setTagList(state, newValue) {
        state.tagList = newValue
    },
    setTag(state, newValue) {
        state.tag = newValue
    },
    setRequirements(state, newValue) {
        state.requirements= newValue
    },
    setSkillCategory(state, newValue) {
        state.skillCategory= newValue
    },
    setSkills(state, newValue) {
        state.skills= newValue
    },
    setSkillCategoryList(state, newValue) {
        state.skillCategoryList = newValue
    },
    setJobs(state, newValue) {
        state.jobs = newValue
    }
};

export const actions = {
    //position
    async addPosition({commit},form) {
       commit('setLoading',true)
       await HTTP.post(`job-position`,form).then(({data}) => {
            commit('setErrorFalse',false)
            commit('setSuccess',data.message)

        }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
        })
        commit('setLoading',false)
    },
    async editPosition({commit},form) {
        commit('setLoading',true)
        await HTTP.put(`plan-position/${form.id}`,{title:form.title}).then(({data}) => {
             commit('setErrorFalse',false)
             commit('setSuccess',data.message)
 
         }).catch(error=>{
             commit('setSuccess',false)
             commit('setError',error.response)
         })
         commit('setLoading',false)
     },
    async getPosition({commit},data) {
        commit('setLoading',true)
        await HTTP.get(`job-position?rows=${data.rows}&page=${data.page}&search=${data.search}`).then(({data}) => {
            commit('setPosition',data.data.data)
            commit('setPagination',data.data)

         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async deletePosition({commit},id) {
        commit('setLoading',true)
        await HTTP.delete(`job-position/${id}`).then(({data}) => {
            commit('setErrorFalse',false)
            commit('setSuccess',data.message)
         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     //tags
     async getTagList({commit}) {
        commit('setLoading',true)
        await HTTP.get(`job-tags/list`).then(({data}) => {
            commit('setTagList',data.data)
         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async getTags({commit},data) {
        commit('setLoading',true)
        await HTTP.get(`job-tags?rows=${data.rows}&page=${data.page}&search=${data.search}`).then(({data}) => {
            commit('setTag',data.data.data)
            commit('setPagination',data.data)

         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async addTags({commit},form) {
        commit('setLoading',true)
        await HTTP.post(`job-tags`,form).then(({data}) => {
             commit('setErrorFalse',false)
             commit('setSuccess',data.message)
 
         }).catch(error=>{
             commit('setSuccess',false)
             commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async editTag({commit},form) {
        commit('setLoading',true)
        await HTTP.put(`job-tags/${form.id}`,{tag:form.tag}).then(({data}) => {
             commit('setErrorFalse',false)
             commit('setSuccess',data.message)
 
         }).catch(error=>{
             commit('setSuccess',false)
             commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async deleteTags({commit},id) {
        commit('setLoading',true)
        await HTTP.delete(`job-tags/${id}`).then(({data}) => {
            commit('setErrorFalse',false)
            commit('setSuccess',data.message)
         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     //requirements
     async addRequirements({commit},form) {
        commit('setLoading',true)
        await HTTP.post(`job-requirement`,form).then(({data}) => {
             commit('setErrorFalse',false)
             commit('setSuccess',data.message)
 
         }).catch(error=>{
             commit('setSuccess',false)
             commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async getRequirements({commit},data) {
        commit('setLoading',true)
        await HTTP.get(`job-requirement?rows=${data.rows}&page=${data.page}&search=${data.search}`).then(({data}) => {
            commit('setRequirements',data.data.data)
            commit('setPagination',data.data)

         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async deleteRequirement({commit},id) {
        commit('setLoading',true)
        await HTTP.delete(`job-requirement/${id}`).then(({data}) => {
            commit('setErrorFalse',false)
            commit('setSuccess',data.message)
         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async editRequirements({commit},form) {
        commit('setLoading',true)
        await HTTP.put(`job-requirement/${form.id}`,{title:form.title}).then(({data}) => {
             commit('setErrorFalse',false)
             commit('setSuccess',data.message)
 
         }).catch(error=>{
             commit('setSuccess',false)
             commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     
     //skills
     async getSkills({commit},data) {
        commit('setLoading',true)
        await HTTP.get(`job-skill?rows=${data.rows}&page=${data.page}&search=${data.search}`).then(({data}) => {
            commit('setSkills',data.data.data)
            commit('setPagination',data.data)

         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async addSkills({commit},form) {
        commit('setLoading',true)
        await HTTP.post(`job-skill`,form).then(({data}) => {
             commit('setErrorFalse',false)
             commit('setSuccess',data.message)
 
         }).catch(error=>{
             commit('setSuccess',false)
             commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async deleteSkills({commit},id) {
        commit('setLoading',true)
        await HTTP.delete(`job-skill/${id}`).then(({data}) => {
            commit('setErrorFalse',false)
            commit('setSuccess',data.message)
         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     //skill category
     async getSkillCategoryList({commit}) {
        commit('setLoading',true)
        await HTTP.get(`job-skill-category/list`).then(({data}) => {
            commit('setSkillCategoryList',data.data)
         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async getSkillCategory({commit},data) {
        commit('setLoading',true)
        await HTTP.get(`job-skill-category?rows=${data.rows}&page=${data.page}&search=${data.search}`).then(({data}) => {
            commit('setSkillCategory',data.data.data)
            commit('setPagination',data.data)

         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
    
     async addSkillCategory({commit},form) {
        commit('setLoading',true)
        await HTTP.post(`job-skill-category`,form).then(({data}) => {
             commit('setErrorFalse',false)
             commit('setSuccess',data.message)
 
         }).catch(error=>{
             commit('setSuccess',false)
             commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async deleteSkillCategory({commit},id) {
        commit('setLoading',true)
        await HTTP.delete(`job-skill-category/${id}`).then(({data}) => {
            commit('setErrorFalse',false)
            commit('setSuccess',data.message)
         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async editSkillCategory({commit},form) {
        commit('setLoading',true)
        await HTTP.put(`job-skill-category/${form.id}`,{title:form.title}).then(({data}) => {
             commit('setErrorFalse',false)
             commit('setSuccess',data.message)
 
         }).catch(error=>{
             commit('setSuccess',false)
             commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     // jobs
     async getJobs({commit},data) {
        commit('setLoading',true)
        await HTTP.get(`jobs?row=${data.rows}&page=${data.page}&search=${data.search}`).then(({data}) => {
            commit('setJobs',data.data.data)
            commit('setPagination',data.data)

         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async editJobs({commit},form) {
        commit('setLoading',true)
        await HTTP.put(`jobs/${form.id}`,form).then(({data}) => {
             commit('setErrorFalse',false)
             commit('setSuccess',data.message)
 
         }).catch(error=>{
             commit('setSuccess',false)
             commit('setError',error.response)
         })
         commit('setLoading',false)
     },
};
