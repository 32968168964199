import { HTTP } from "../https";
import router from "../../router"

export const state = {
    user: {
        data: {},
        token: localStorage.getItem("cl_tkn") || ''
    },
    isLogin:false,
    isError:{
        content:false,
        message:''
    },
    isLoading:false

};

export const getters = {
    loginError: state => state.isError,
    processingData: state => state.isLoading,
    loggedIn: state => state.isLogin
};

export const mutations = {

    setUser(state, newValue) {
        state.user.data = newValue
    },
    setToken(state,newValue){
        state.user.token = newValue
        
    },
    setIsLogin(state,newValue){
        state.isLogin = newValue
    },
    setError(state,newValue){
        state.isError = {
            content:true,
            message:newValue,
        }
    },
    setLoading(state, newValue) {
        state.isLoading = newValue
    },

};

export const actions = {
   async login({commit},account) {
       commit('setLoading',true)
       await HTTP.post(`login`,account).then(({data}) => {
            commit('setUser', data.data.user)
            commit('setToken', data.data.token)
            commit('setIsLogin', true)
            localStorage.setItem("cl_tkn",data.data.token)
            router.push('/')
        }).catch(()=>{
            commit('setError','Invalid Login. Please try again')
        })
        commit('setLoading',false)
    },
   async validate({commit}){
        return  await HTTP.get('validuser')
        .then(({data}) => {
          commit('setUser', data.data)
          commit('setIsLogin', true)
          return data;
      })
    },
   async signout({commit}){
       return await HTTP.get(`logout`).then(() => {
            commit('setUser',{})
            commit('setToken','')
            commit('setIsLogin', false)

            localStorage.removeItem("cl_tkn")
        }).catch(()=>{
            commit('setError','Something went wrong')
        })
   }
};
