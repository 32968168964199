// import Vue from "vue";
// import VueRouter from "vue-router";

import { createWebHistory, createRouter } from "vue-router";
import store from "@/state/store";
import routes from "./routes";
// import VueMeta from "vue-meta";


// Vue.use(VueRouter);

// Vue.use(VueMeta, {
//   // The component option name that vue-meta looks for meta info on.
//   keyName: "page",
// });
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    const token  =  localStorage.getItem("cl_tkn") || false
    const authRequired = routeTo.matched.some(
      (route) => route.meta.authRequired
    );
    const isGuest = routeTo.matched.some(
      (route) => route.meta.isGuest
    );
    if(authRequired && token) {
      if(store.getters['auth/loggedIn']){
        return next()
      }else{
        return store.dispatch("auth/validate").then((validUser) => {
          validUser ? next() : redirectToLogin();
        });
      }
    }else if(authRequired && !token){
      return redirectToLogin();
    }else if(isGuest && token){
      return next({name:"dashboard"});
    }else{
      return next();
    }
  
    function redirectToLogin() {
      next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
    }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }
  next();
});

export default router;
