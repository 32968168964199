

export default [{
    path: '/',
    name: 'dashboard',
    component: () => import('../views/pages/dashboard/ecommerce/index.vue'),
    meta: { authRequired: true},
  },


  /* job management route --start here */
  {
    path: '/job-management/posting',
    name: 'posting',
    component: () => import('../views/pages/job-management/posting/postingLayout.vue'),
    meta: { authRequired: true},
  },
  {
    path: '/job-management/engagement',
    name: 'engagement',
    component: () => import('../views/pages/job-management/engagements/starter.vue'),
    meta: { authRequired: true},
  },
  {
    path: '/job-management/summary-report',
    name: 'summary',
    component: () => import('../views/pages/job-management/summary/starter.vue'),
    meta: { authRequired: true},
  },
  {
    path: '/job-management/settings/:page',
    name: 'jobManagementSettings',
    component: () => import('../views/pages/job-management/settings/index.vue'),
    meta: { authRequired: true},
  },
  {
    path: '/job-management/settings',
    name: 'jobManagementSettingsDefault',
    component: () => import('../views/pages/job-management/settings/index.vue'),
    meta: { authRequired: true},
  },
  /* job management route --end here */

  /* subscription route --start here*/
  {
    path: '/subscription/plans',
    name: 'plans',
    component: () => import('../views/pages/subscription/plans/plansLayout.vue'),
    meta: { authRequired: true}
  },
  {
    path: '/subscription/plan-inclusion',
    name: 'inclusion',
    component: () => import('../views/pages/subscription/inclusion/inclusionLayout.vue'),
    meta: { authRequired: true}
  },
  {
    path: '/subscription/subscriber',
    name: 'subscriber',
    component: () => import('../views/pages/subscription/subscriber/subscriberLayout.vue'),
    meta: { authRequired: true}
  },
  /* subscription route --end here*/

  {
    path: '/auth/login',
    name: 'login',
    component: () => import('../views/pages/auth/login.vue'),
    meta: { isGuest: true},

  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: () => import('../views/pages/auth/logout.vue'),
    meta: { isGuest: true},
  },

]