import { HTTP } from "../https";

export const state = {
    inclusion: {
    },
    plan:{

    },
    subscriber:{

    },
    inclusionList:[],
    pagination:{
        currentPage:0,
        rows:0,
        perPage:0
    },
    isError:{
        content:false,
        message:''
    },
    success:false,
    isLoading:false

};

export const getters = {
    processingData: state => state.isLoading,
    error: state => state.isError.content,
    success: state => state.success,
    getInclusion: state => state.inclusion,
    getPlan: state => state.plan,
    getInclusionList: state => state.inclusionList,
    getSubscriber: state => state.subscriber,
    getPagination: state => state.pagination,
  
};

export const mutations = {

    setPlan(state, newValue) {
        state.plan = newValue
    },
    setSubscriber(state, newValue) {
        state.subscriber = newValue
    },
    setInclusion(state, newValue) {
        state.inclusion = newValue
    },
    setInclusionList(state, newValue) {
        state.inclusionList = newValue
    },
    setLoading(state,newValue){
        state.isLoading = newValue
    },
    setError(state,newValue){
        state.isError.content = newValue.data.data
        state.isError.message = newValue.data.message
    },
    setErrorFalse(state,newValue){
        state.isError.content = newValue
        state.isError.message =''
    },
    setSuccess(state,newValue){
        state.success = newValue
    },
    setPagination(state,newValue){
        state.pagination.currentPage = newValue.current_page
        state.pagination.rows = newValue.total
        state.pagination.perPage = newValue.per_page
    },

};

export const actions = {
    async addInclusion({commit},form) {
       commit('setLoading',true)
       await HTTP.post(`plan-inclusion`,form).then(({data}) => {
            commit('setErrorFalse',false)
            commit('setSuccess',data.message)

        }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
        })
        commit('setLoading',false)
    },
    async editInclusion({commit},form) {
        commit('setLoading',true)
        await HTTP.put(`plan-inclusion/${form.id}`,{title:form.title}).then(({data}) => {
             commit('setErrorFalse',false)
             commit('setSuccess',data.message)
 
         }).catch(error=>{
             commit('setSuccess',false)
             commit('setError',error.response)
         })
         commit('setLoading',false)
     },
    async getInclusionList({commit}){
        commit('setLoading',true)
        await HTTP.get(`plan-inclusion/list`).then(({data}) => {
            commit('setInclusionList',data.data)
        

         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     
    },
    async getInclusion({commit},data) {
        commit('setLoading',true)
        await HTTP.get(`plan-inclusion?page=${data.page}&search=${data.search}`).then(({data}) => {
            commit('setInclusion',data.data.data)
            commit('setPagination',data.data)

         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async deleteInclusion({commit},id) {
        commit('setLoading',true)
        await HTTP.delete(`plan-inclusion/${id}`).then(({data}) => {
            commit('setErrorFalse',false)
            commit('setSuccess',data.message)
         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     

     //plan
     async addPlan({commit},form) {
        commit('setLoading',true)
        await HTTP.post(`plan`,form).then(({data}) => {
             commit('setErrorFalse',false)
             commit('setSuccess',data.message)
 
         }).catch(error=>{
             commit('setSuccess',false)
             commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async getPlan({commit},data) {
        commit('setLoading',true)
        await HTTP.get(`plan?page=${data.page}&search=${data.search}`).then(({data}) => {
            commit('setPlan',data.data.data)
            commit('setPagination',data.data)

         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     async deletePlan({commit},id) {
        commit('setLoading',true)
        await HTTP.delete(`plan/${id}`).then(({data}) => {
            commit('setErrorFalse',false)
            commit('setSuccess',data.message)
         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
     //subscriber
     async getSubscriber({commit},data) {
        commit('setLoading',true)
        await HTTP.get(`plan-subscriber?row=${data.rows}&page=${data.page}&search=${data.search}`).then(({data}) => {
            commit('setSubscriber',data.data.data)
            commit('setPagination',data.data)
         }).catch(error=>{
            commit('setSuccess',false)
            commit('setError',error.response)
         })
         commit('setLoading',false)
     },
};
